import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Layout } from "../components/layout"
import { DividerBlock } from "../components/dividerBlock"
import { EditionsList } from "../components/editionsList"
import { SwiperGallery } from "../components/swiperGallery.tsx"
import ImageFadeIn from "react-image-fade-in"

const Edition = ({ data }) => {
  const edition = data.datoCmsSingleEdition
  const gertrudeEdition = data.datoCmsGertrudeEdition
  const topSideBarText = "Gertrude Editions"
  const listSideBarText = "Other Editions"
  const workDetailsHeading = "Work Details"
  const editionTitle = `${edition.year}: ${edition.title}`

  return (
    <Layout
      bgcolor={gertrudeEdition.backgroundColor}
      wrapperClass="single-edition"
      accentColor={gertrudeEdition.accentColor}
    >
      <HelmetDatoCms seo={edition.seoMetaTags} />
      <div className="container row">
        <div className="col-sm-2 col-xs-6 col">
          <Link to="/gertrude-editions" className="sidebar-text">
            {topSideBarText}
          </Link>
        </div>
        <div className="col-sm-9 col-xs-6 col">
          <h1 className="gothic--xlarge large-title">{editionTitle}</h1>
        </div>
      </div>
      <div className="container row reverse single-article-content">
        <div className="publication-info col-sm-4 offset-sm-1 col-xs-6 col">
          <h4 className="sans--regular">{workDetailsHeading}</h4>
          <div
            className="content main-intro publication-text"
            dangerouslySetInnerHTML={{
              __html: edition.workDetails,
            }}
          />
          <div className="divider-intro">
            <DividerBlock accentColor={gertrudeEdition.accentColor} />
          </div>
        </div>
        <div className="col-sm-7 offset-sm-2 col-xs-6 col">
          <div className="feature-image">
            {edition.featuredImage && (
							<ImageFadeIn
								src={edition.featuredImage.url}
								className="edition-featured-image"
								alt={edition.featuredImage.alt}
							/>
            )}
          </div>
          <div className="main-body">
            <div
              className="content text"
              dangerouslySetInnerHTML={{
                __html: edition.description,
              }}
            />
						{edition.downloadFile && (
							<div className="download-file">
							<Link to={edition.downloadFile.url} target="_blank" rel="noreferrer" download={edition.downloadText}>
								<svg width="40" height="40" viewBox="0 0 40 40" fill="none">
									<circle cx="20" cy="20" r="19" stroke="black" strokeWidth="2" />
									<path d="M11.5791 23.1578L20.0002 31.5789L28.4212 23.1578" fill="none" stroke="black" strokeWidth="2" />
									<path d="M20 7.36841L20 31.5789" fill="none" stroke="black" strokeWidth="2" />
								</svg>
								<div className="text-wrapper">
									<p style={{ marginBottom: '0', textDecoration: 'underline' }}>{edition.downloadText}</p>
								</div>
							</Link>
						</div>
						)}
          </div>
        </div>
      </div>
      {edition.imageGallery.length > 0 && (
        <div className="swiper-gallery">
          <SwiperGallery slides={edition.imageGallery} />
        </div>
      )}
      <div className="container row">
        <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-3 col">
          <DividerBlock accentColor={gertrudeEdition.accentColor} />
        </div>
      </div>
      <>
        <div className="editions-list container row">
          <div className="col-sm-2 col-xs-6 col">
            <span className="sidebar-text">{listSideBarText}</span>
          </div>
          <EditionsList editionId={edition.id} />
        </div>
      </>
    </Layout>
  )
}

Edition.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default Edition

export const query = graphql`
  query SingleEditionQuery($slug: String!) {
    datoCmsSingleEdition(slug: { eq: $slug }) {
      id
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      featuredImage {
				url(imgixParams: { w: "1400" , fit: "crop", crop: "focalpoint" })
				alt
      }
      title
      description
      year
      workDetails
      imageGallery {
        title
        url(imgixParams: { h: "1000", fit: "clip" })
      }
			downloadFile {
				url
			}
			downloadText
    }
    datoCmsGertrudeEdition {
      backgroundColor
      accentColor {
        hex
      }
    }
  }
`
