import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import ImageFadeIn from "react-image-fade-in"

export const EditionsList = ({ editionId }) => {
  const data = useStaticQuery(graphql`
    query EditionsListQuery {
      singleEditions: allDatoCmsSingleEdition(
        sort: { fields: [year], order: DESC }
      ) {
        edges {
          node {
            id
            slug
            title
            featuredImage {
              fluid {
                src
              }
              url(
                imgixParams: {
                  w: "600", ar: "5:3", fit: "crop", crop: "focalpoint"
                }
              )
              alt
            }
            year
            description
            workDetails
          }
        }
      }
    }
  `)

  function getRandomEditions(editions, numberOfEditions = 4) {
    return editions
      .map(edition => ({ edition, randomNumber: Math.random() }))
      .sort((a, b) => a.randomNumber - b.randomNumber)
      .map(entry => entry.edition)
      .slice(0, numberOfEditions)
  }

  const allEditions = data.singleEditions.edges
  const uniqueEditions = allEditions.filter(
    edition => edition.node.id !== editionId
  )
  const filteredEditions = editionId
    ? getRandomEditions(uniqueEditions)
    : allEditions

  return (
    <div className="tiles tiles-4 col-sm-12 col-xs-6 col row spacing-0">
      {filteredEditions.map(edition => (
        <div className="tile col" key={edition.node.id}>
          <Link
            to={`/gertrude-editions/${edition.node.slug}`}
            className="edition-tile"
          >
            {edition.node.featuredImage && (
              <ImageFadeIn
                src={edition.node.featuredImage.url}
                className="tile-image"
                aspectRatio={16 / 9}
                alt={edition.node.featuredImage.alt}
              />
            )}
            <div className="tile-text-wrap">
              <h3
                className="sans--regular item-title"
                dangerouslySetInnerHTML={{
                  __html: edition.node.title,
                }}
              />
              <span className="edition-year">{edition.node.year}</span>
            </div>
          </Link>
        </div>
      ))}
    </div>
  )
}

EditionsList.propTypes = {
  editionId: PropTypes.string,
}

EditionsList.defaultProps = {
  editionId: "",
}
